<template>
  <form id="login-form-container" novalidate autofocus autocomplete="off">
    <vs-input
        v-validate="'required|email|min:3'"
        data-vv-validate-on="blur"
        name="email"
        id="login-email"
        v-on:keyup.enter="onEnter"
        icon-no-border
        icon="icon icon-user"
        icon-pack="feather"
        label-placeholder="Email"
        v-model="email"
        ref="email"
        tabindex="1"
        class="w-full"/>
    <span class="text-danger text-sm">{{ errors.first('email') }}</span>

    <vs-input
        data-vv-validate-on="blur"
        autocomplete="off"
        v-on:keyup.enter="onEnter"
        tabindex="2"
        v-validate="'required|min:4'"
        type="password"
        name="password"
        id="login-password"
        icon-no-border
        icon="icon icon-lock"
        icon-pack="feather"
        label-placeholder="Password"
        ref="password"
        v-model="password"
        class="w-full mt-8" />
    <span class="text-danger text-sm">{{ errors.first('password') }}</span>

    <div class="flex flex-wrap justify-between my-5">
        <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox>
        <router-link to="/auth/forgot-password">Forgot Password?</router-link>
    </div>
    <div class="flex flex-wrap justify-between mb-3">
      <vs-button  type="border" @click="registerUser">Register</vs-button>
      <vs-button type="border" :disabled="!validateForm" @click="loginAmplify">Login</vs-button>
    </div>
  </form>
</template>

<script>
export default {
  data () {
    return {
      email: '',
      password: '',
      checkbox_remember_me: false
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.email !== '' && this.password !== ''
    }
  },
  methods: {
    onEnter (event) {
      try {
        let el = event.target.getAttribute("id");
        if (el === 'login-email') {
          if (this.email) this.$refs.password.focusInput();
        } else if (this.email && this.password) {
          this.loginAmplify()
        }
      } catch(e){console.log(e);}
      console.log(this.$refs.password);
    },
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },
    loginAmplify () {

      if (!this.checkLogin()) return

      // Loading
      this.$vs.loading()

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password
        }
      }

      this.$store.dispatch('auth/loginAmplify', payload)
        .then(resp => {
          this.$vs.loading.close()
          if(resp.message){
            this.$vs.notify({
              title: 'Welcome',
              text: resp.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            })
          }
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    registerUser () {
      if (!this.checkLogin()) return
      this.$router.push('/register').catch(() => {})
    },
    focusInput () {
      try {
        setTimeout(() => {
          this.$refs.email.focusInput();
        }, 500);
      } catch(e){console.log(e);}
    }
  },
  mounted () {
    this.focusInput()
    if (!this.checkLogin()) return
  }
}

</script>
